<template>
    <default-app-template>
        <div>
            <PatientDataStepper :active-step="step" :steps="steps" />

            <div class="patient-forms">
                <div v-if="step === 1">
                    <PatientDemographicsForm @next="nextStep()" />
                </div>
                <div v-else-if="step === 2" class="">
                    <PatientDiagnosesForm
                        @next="nextStep()"
                        @back="previousStep()"
                    />
                </div>
                <div v-else-if="step === 3" class="">
                    <PatientMedicationForm
                        @next="nextStep()"
                        @back="previousStep()"
                    />
                </div>
                <div v-else class="">
                    <DaySpecificConditions
                        @next="nextStep()"
                        @back="previousStep()"
                    />
                </div>
            </div>
        </div>
    </default-app-template>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            validation: {},
            steps: [
                { label: 'Demographics', number: 1 },
                { label: 'Diagnosis', number: 2 },
                { label: 'Medication', number: 3 },
                { label: 'Day Specific Condition', number: 4 },
            ],
        }
    },
    computed: {
        ...mapState('measurement', {
            measurement: state => state.active,
        }),
        ...mapState('patient', {
            patient: state => state.currentPatient,
        }),
        ...mapGetters('measurement', {
            step: 'getCurrentStep',
            isComplete: 'isComplete',
        }),

        ...mapGetters('ui', ['loading']),
    },

    mounted() {
        if (this.step >= 5) {
            this.decreaseStep(4)
        }
    },

    methods: {
        hasPreviousStep(step) {
            return step == 1 ? false : true
        },
        nextStep() {
            this.increaseStep()

            // Go to Named Route on Last Step
            if (this.step === 5) {
                this.$router.push({
                    name: 'CreateMeasurement',
                    params: { uuid: this.measurement.uuid },
                })
            }
        },

        previousStep() {
            if (this.step > 5) {
                this.decreaseStep(3)
            } else {
                this.decreaseStep()
            }
        },

        // VUEX
        ...mapActions('measurement', [
            'new', // -> this.new()
            'increaseStep',
            'decreaseStep',
        ]),
    },
}
</script>
